import {
	Button,
	Container,
	Errors,
	Form,
	Heading,
	HiddenFields,
	Link,
	Page,
	Section,
	SectionStepHeading,
	TextLink,
} from '@troon/ui';
import { createEffect, Match, Show, Suspense, Switch } from 'solid-js';
import { useTrackEvent } from '@troon/analytics';
import { createAsync, useSubmission } from '@solidjs/router';
import { cachedGet, clientAction } from '@troon/api-client';
import { Title } from '@solidjs/meta';
import { IconCircleCheckCustom } from '@troon/icons/circle-check-custom';
import { useUser } from '../../../providers/user';
import { AuthFlow } from '../../../partials/auth/auth';
import { logout } from '../../auth/components/logout-action';
import { NotFoundContent } from '../../../partials/404';
import { UserRewardsLevel } from '../../../graphql';
import { authHeaders } from '../../../partials/auth/inline';
import type { operations } from '@troon/api-client';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

type ProgramType = operations['ProgramsController_getSignupProgram_v0']['parameters']['path']['programSignUpType'];

export default function VerifyVisaRewards(props: RouteSectionProps) {
	const user = useUser();
	const trackEvent = useTrackEvent();
	const program = createAsync(
		() =>
			getProgram({
				path: { programSignUpType: props.params.program?.toUpperCase().replace(/-/g, '_') as ProgramType },
			}),
		{ deferStream: true },
	);

	// eslint-disable-next-line solid/reactivity
	const handleLogout = logout(trackEvent, `/rewards/visa/verify${props.params.cardType ?? ''}`);
	const submission = useSubmission(enrollInProgram);

	createEffect(() => {
		if (submission.result) {
			trackEvent('rewardsProgramEnroll', { rewardsProgram: props.params.program });
		}
	});

	return (
		<Suspense>
			<Show when={program()?.data} fallback={<NotFoundContent />}>
				{(program) => (
					<>
						<Title>Verify your {program().name} Benefits | Troon Rewards | Troon</Title>
						<div class="h-screen grow bg-neutral-100">
							<Container>
								<Page>
									<Switch>
										<Match when={!submission.result}>
											<Container size="medium">
												<Heading as="h1">Activate {program().name} Benefits</Heading>
												<Section appearance="contained">
													<Show
														when={user()}
														fallback={
															<div class="flex flex-col gap-4">
																<SectionStepHeading step={1} state="current">
																	Log in or sign up
																</SectionStepHeading>
																<AuthFlow inline headers={authHeaders} showSteps={false} />
															</div>
														}
													>
														{(user) => (
															<>
																<SectionStepHeading
																	step={1}
																	state="completed"
																	action={
																		<TextLink
																			href="/auth/logout"
																			onClick={(e) => {
																				e.preventDefault();
																				handleLogout();
																			}}
																		>
																			Log out
																		</TextLink>
																	}
																>
																	Account information
																</SectionStepHeading>

																<ul class="flex flex-col gap-1">
																	<li>
																		{user().me.firstName} {user().me.lastName}
																	</li>
																	<li>Email address: {user().me.email}</li>
																	<li>Troon Rewards #{user().me.troonRewardsId}</li>
																</ul>
															</>
														)}
													</Show>
												</Section>

												<Section appearance="contained">
													<SectionStepHeading step={2} state={user() ? 'current' : 'waiting'}>
														Activate Your Benefits
													</SectionStepHeading>
													<Show when={user()}>
														<Form action={enrollInProgram}>
															<Show when={program().rewardsLevel}>
																{(level) => (
																	<Switch>
																		<Match when={user()?.userRewards.level !== UserRewardsLevel.Member}>
																			<p>
																				Activate your benefits to automatically receive one Troon Rewards® level
																				increase.
																			</p>
																		</Match>
																		<Match when>
																			<p>
																				Activate your benefits to be automatically elevated to Troon Rewards® {level()}{' '}
																				status.
																			</p>
																		</Match>
																	</Switch>
																)}
															</Show>
															<HiddenFields
																data={{ __program: props.params.program?.toUpperCase().replace(/-/g, '_') }}
															/>
															<Errors />
															<Button type="submit">Activate</Button>
														</Form>
													</Show>
												</Section>
											</Container>
										</Match>
										<Match when={submission.result}>
											<div class="flex flex-col gap-8">
												<Container class="max-w-screen-md">
													<div class="flex flex-col items-center gap-6 text-center">
														<IconCircleCheckCustom class="text-8xl" />
														<Heading as="h1" class="leading-snug md:leading-snug">
															Your {program().name} benefits have been activated!
														</Heading>
														<p class="text-lg">
															You now have <b>{submission.result?.newRewardsLevel}</b> Troon Rewards status.
														</p>
														<Button as={Link} href="/tee-times">
															Find a Tee Time
														</Button>
													</div>
												</Container>
												<Container size="xsmall">
													<Section appearance="contained">
														<Heading as="h2" size="h4">
															Summary
														</Heading>

														<div class="divide-y divide-neutral">
															<p class="flex justify-between py-4">
																<span>Previous Troon Rewards® Level</span>
																<span>{submission.result?.originalRewardsLevel}</span>
															</p>
															<p class="flex justify-between py-4">
																<b>New Troon Rewards® Level</b>
																<b>{submission.result?.newRewardsLevel}</b>
															</p>
														</div>
													</Section>
													<p class="text-center text-sm">
														<TextLink href={`/rewards/visa/${props.params.program}/terms`}>
															Terms and Conditions
														</TextLink>
													</p>
												</Container>
											</div>
										</Match>
									</Switch>
								</Page>
							</Container>
						</div>
					</>
				)}
			</Show>
		</Suspense>
	);
}

export const route = {
	info: { nav: { appearance: 'extra-minimal' }, banner: { hide: true } },
} satisfies RouteDefinition;

const getProgram = cachedGet('/v0/rewards/programs/{programSignUpType}');

const enrollInProgram = clientAction('POST', '/v0/rewards/programs/{programSignUpType}', (data) => ({
	params: { path: { programSignUpType: data.get('__program') as ProgramType } },
}));
